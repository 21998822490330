<template>
  <div class="list-container">
    <div class="list">
      <div class="col-md-12 text-center">
        {{ leftLabel }}
      </div>
      <b-form-input
        v-model="query"
        class="d-inline-block mr-1"
        :placeholder="$t('table.search.search')"
      />
      <b-form-select
        v-model="leftSelectedData"
        multiple
        :options="availableData"
        text-field="name"
        value-field="id"
        @dblclick="moveRight"
        @change="selectedLeftItem"
      />
      <div class="col-md-12 text-center">
        <b-button
          variant="outline-secondary"
          :disabled="moveAllRightButtonDisabled"
          @click="moveAllRight"
        >
          {{ $t('user.roles.selectAll') }}
          <svg-icon
            type="mdi"
            size="30px"
            :path="mdiChevronDoubleRight"
          />
        </b-button>
      </div>
    </div>

    <div class="middle">
      <b-button
        variant="outline-secondary"
        :disabled="rightButtonDisabled"
        @click="moveRight"
      >
        <svg-icon
          type="mdi"
          size="30px"
          :path="mdiArrowRightBoldCircle"
        />
      </b-button>
      <br>
      <b-button
        variant="outline-secondary"
        :disabled="leftButtonDisabled"
        @click="moveLeft"
      >
        <svg-icon
          type="mdi"
          size="30px"
          :path="mdiArrowLeftBoldCircle"
        /></b-button>
    </div>

    <div class="list">
      <div class="col-md-12 text-center">
        {{ rightLabel }}
      </div>
      <b-form-select
        v-model="rightSelectedData"
        multiple
        :options="rightData"
        text-field="name"
        value-field="id"
        @dblclick="moveLeft"
        @change="selectedRightItem"
      />
      <div class="col-md-12 text-center">
        <b-button
          variant="outline-secondary"
          :disabled="moveAllLeftButtonDisabled"
          @click="moveAllLeft"
        >
          <svg-icon
            type="mdi"
            size="30px"
            :path="mdiChevronDoubleLeft"
          />
          {{ $t('user.roles.removeAll') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import { BButton, BFormSelect, BFormInput } from 'bootstrap-vue'
import SvgIcon from '@jamescoyle/vue-icon'
import {
  mdiArrowLeftBoldCircle, mdiArrowRightBoldCircle, mdiChevronDoubleLeft, mdiChevronDoubleRight,
} from '@mdi/js'

export default {
  components: {
    SvgIcon,
    BButton,
    BFormSelect,
    BFormInput,
  },
  props: {
    leftLabel: {
      type: String,
      required: true,
    },
    rightLabel: {
      type: String,
      required: true,
    },
    leftData: {
      type: Array,
      required: false,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mdiArrowRightBoldCircle,
      mdiArrowLeftBoldCircle,
      mdiChevronDoubleLeft,
      mdiChevronDoubleRight,
      leftSelectedData: [],
      rightSelectedData: [],
      availableData: [],
      query: '',
      leftButtonDisabled: true,
      rightButtonDisabled: true,
      moveAllLeftButtonDisabled: true,
      moveAllRightButtonDisabled: true,
      rightData: this.value,
    }
  },
  watch: {
    value(val) {
      this.rightData = this.value
    },
    leftData(val) {
      this.updateAvailableData()
    },
    rightData(val) {
      this.$emit('input', val)
      this.$emit('change', val)
      this.updateAvailableData()
    },
    availableData(val) {
      this.updateState()
    },
    query(val) {
      if (val.length >= 3 || val.length == 0) {
        this.updateAvailableData()
      }
    },
  },
  methods: {
    updateState() {
      this.leftButtonDisabled = this.rightSelectedData.length == 0
      this.rightButtonDisabled = this.leftSelectedData.length == 0
      this.moveAllLeftButtonDisabled = this.rightData.length == 0
      this.moveAllRightButtonDisabled = this.availableData.length == 0
    },
    selectedLeftItem(value) {
      this.rightButtonDisabled = value.length == 0
    },
    selectedRightItem(value) {
      this.leftButtonDisabled = value.length == 0
    },
    updateAvailableData() {
      this.availableData = []
      for (let i = 0; i < this.leftData.length; i++) {
        let isSelected = false
        for (let j = 0; j < this.rightData.length; j++) {
          if (this.leftData[i].id == this.rightData[j].id) {
            isSelected = true
          }
        }
        if (!isSelected && (this.query == '' || this.leftData[i].name.toUpperCase().includes(this.query.toUpperCase()))) {
          this.availableData.push(this.leftData[i])
        }
      }
    },
    moveLeft() {
      if (!this.rightSelectedData.length) return
      for (let i = this.rightSelectedData.length; i > 0; i--) {
        for (let j = 0; j < this.rightData.length; j++) {
          if (this.rightData[j].id == this.rightSelectedData[i - 1]) {
            this.rightData.splice(j, 1)
          }
        }
        this.rightSelectedData.pop()
      }
    },
    moveRight() {
      if (!this.leftSelectedData.length) return
      for (let i = this.leftSelectedData.length; i > 0; i--) {
        for (let j = 0; j < this.availableData.length; j++) {
          if (this.availableData[j].id == this.leftSelectedData[i - 1]) {
            this.rightData.push(this.availableData[j])
          }
        }
        this.leftSelectedData.pop()
      }
    },
    moveAllLeft() {
      for (let j = this.rightData.length - 1; j >= 0; j--) {
        this.rightData.splice(j, 1)
      }
      this.rightSelectedData = []
    },
    moveAllRight() {
      for (let j = 0; j < this.availableData.length; j++) {
        this.rightData.push(this.availableData[j])
      }
      this.leftSelectedData = []
    },
  },
}
</script>

<style scoped>

.list-container .list{
  border-radius: 5px;
  margin: 10;
  padding: 10;
}
.list-container {
  display: grid;
  grid-template-columns: 45% 10% 45%;
  align-items: center;
}

.list-container select {
  height: 200px;
  width: 100%;
}

.list-container .middle {
  text-align: center;
}

.list-container button {
  margin-bottom: 5px;
}
</style>
