<template>
  <div>
    <validation-observer
      ref="userForm"
      #default="{ invalid }"
    >
      <b-form
        @submit.prevent="save"
      >
        <!-- User Info: Input Fields -->
        <div :is="userData === undefined ? 'div' : 'b-card'">
          <b-row>
            <b-col
              cols="6"
              md="6"
              lg="6"
            >
              <!-- Media -->
              <b-media>
                <template #aside>
                  <b-avatar
                    :src="avatar"
                    text=""
                    variant="light-success"
                    size="185px"
                    rounded
                  />
                </template>
                <h2 class="mb-1 text-primary text-uppercase">
                  <b>{{ userData.username }}</b>
                </h2>
                <div class="mb-1">
                  <div class="mb-1">
                    <b-button
                      variant="primary"
                      @click="$refs.refInputAvatar.click()"
                    >
                      <input
                        ref="refInputAvatar"
                        type="file"
                        class="d-none"
                        @change="handleAvatarImage()"
                      >
                      <span class="d-none d-sm-inline">{{ $t('user.updateAvatar') }}</span>
                      <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                  </div>
                  <div class="mb-1">
                    <b-button
                      variant="danger"

                      @click="removeAvatarImage()"
                    >
                      <span class="d-none d-sm-inline">{{ $t('user.removeAvatar') }}</span>
                      <feather-icon
                        icon="TrashIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                  </div>
                  <div
                    v-if="!newUser"
                    class="mb-1"
                  >
                    <b-button
                      v-b-toggle.password-collapse
                      variant="warning"
                    >
                      {{ $t('user.changePassword') }}
                    </b-button>
                  </div>
                </div>
              </b-media>
            </b-col>
            <b-col
              v-if="!newUser"
              cols="3"
              md="3"
              lg="3"
            >
              <user-edit-password
                :user-data="userData"
                :new-user="newUser"
              />
            </b-col>
            <b-col
              v-else
              cols="4"
              md="4"
              lg="4"
            >
              <b-form-group
                :label="$t('user.username')"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.username')"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="userData.username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('user.password')"
                label-for="password"
              >
                <template v-slot:label>
                  {{ $t('user.password') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.password')"
                  rules="required"
                  vid="password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="userData.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('user.passwordConfirmation')"
                label-for="password"
              >
                <template v-slot:label>
                  {{ $t('user.passwordConfirmation') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.passwordConfirmation')"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password_confirmation"
                      v-model="passwordConfirmation"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              md="3"
              lg="3"
            >
              <div class="text-center">
                <!-- Field: Status -->
                <br><br>
                <b-form-group
                  label-for="user-active"
                >
                  <b-form-checkbox
                    v-model="userData.status"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                  />
                  <h2 class="inline">
                    <b-badge
                      pill
                      :variant="`light-${resolveUserStatusVariant(userData.status)}`"
                      class="text-capitalize mr-0 mt-50"
                    >
                      {{ resolveUserStatusMessage(userData.status) }}
                    </b-badge>
                  </h2>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div :is="userData === undefined ? 'div' : 'b-card'">
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-for="id_type"
              >
                <template v-slot:label>
                  {{ $t('user.idType') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.idType')"
                  rules="required"
                >
                  <v-select
                    v-model="userData.document_type_id"
                    :options="idTypeList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-for="document"
              >
                <template v-slot:label>
                  {{ $t('user.identity') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.identity')"
                  rules="required"
                >
                  <b-form-input
                    id="document"
                    v-model="userData.document"
                    maxlength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-for="firt_name"
              >
                <template v-slot:label>
                  {{ $t('user.firtName') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.firtName')"
                  :rules="{required: true}"
                >
                  <b-form-input
                    id="firt_name"
                    v-model="userData.firt_name"
                    maxlength="100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('user.middleName')"
                label-for="middle_name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.middleName')"
                  rules=""
                >
                  <b-form-input
                    id="middle_name"
                    v-model="userData.middle_name"
                    maxlength="100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('user.surname1')"
                label-for="surname_1"
              >
                <template v-slot:label>
                  {{ $t('user.surname1') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.surname1')"
                  rules="required"
                >
                  <b-form-input
                    id="surname_1"
                    v-model="userData.surname_1"
                    maxlength="100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('user.surname2')"
                label-for="surname_2"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.surname2')"
                  rules=""
                >
                  <b-form-input
                    id="surname_2"
                    v-model="userData.surname_2"
                    maxlength="100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Email -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-for="email"
              >
                <template v-slot:label>
                  {{ $t('user.email') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.email')"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-for="cell_phone"
              >
                <template v-slot:label>
                  {{ $t('user.cellPhone') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.cellPhone')"
                  rules="required|digits:10"
                >
                  <b-form-input
                    id="cell_phone"
                    v-model="userData.cell_phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-for="work_cell_phone"
              >
                <template v-slot:label>
                  {{ $t('user.workCellPhone') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.workCellPhone')"
                  rules="required|digits:10"
                >
                  <b-form-input
                    id="work_cell_phone"
                    v-model="userData.work_cell_phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('user.homeAddress')"
                label-for="home_address"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.homeAddress')"
                  rules=""
                >
                  <b-form-input
                    id="home_address"
                    v-model="userData.home_address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('user.department')"
                label-for="department"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.department')"
                >
                  <v-select
                    v-model="userData.department_id"
                    :options="departmentList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                    @input="onDepartmentChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('user.municipality')"
                label-for="id_ob_title"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.municipality')"
                >
                  <v-select
                    v-model="userData.municipality_id"
                    :options="municipalityList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-for="job_title"
              >
                <template v-slot:label>
                  {{ $t('user.jobTitle') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.jobTitle')"
                  rules="required"
                >
                  <v-select
                    v-model="userData.job_title_id"
                    :options="jobList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="medicalRecord"
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('user.medicalRecord')"
                label-for="medical_record"
              >
                <template v-slot:label>
                  {{ $t('user.medicalRecord') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.medicalRecord')"
                  :rules="medicalRecordRules"
                >
                  <b-form-input
                    id="medical_record"
                    v-model="userData.medical_record"
                    maxlength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="medicalRecord"
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('user.occupationalMedicalLicense')"
                label-for="occupational_medical_license"
              >
                <template v-slot:label>
                  {{ $t('user.occupationalMedicalLicense') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.occupationalMedicalLicense')"
                  :rules="occupationalMedicalLicenseRules"
                >
                  <b-form-input
                    id="occupational_medical_license"
                    v-model="userData.occupational_medical_license"
                    maxlength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                :label="$t('user.role')"
                label-for="role"
              >
                <template v-slot:label>
                  {{ $t('user.role') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  ref="role"
                  :name="$t('user.role')"
                  rules="required"
                >
                  <dual-select
                    id="role"
                    v-model="userData.roles"
                    :left-label="$t('user.roles.available')"
                    :right-label="$t('user.roles.chosen')"
                    :left-data="roleList"
                    @change="onRoleChange()"
                  />
                  <div class="col-md-12 text-center">
                    <small class="text-danger">{{ getEmptyError(errors) }}</small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="arlRequired">
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-for="arl"
              >
                <template v-slot:label>
                  {{ $t('user.arl') }} <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('user.arl')"
                  :rules="arlRules"
                >
                  <v-select
                    v-model="userData.arl_id"
                    :options="arlList"
                    label="name"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!--
                {{ hasRole('AGENDA_COORDINATOR') }}
                <div class="d-inline p-1" v-if="hasRole('AGENDA_COORDINATOR')">
                  <b-button
                    variant="danger"
                  >
                    <span class="d-none d-sm-inline">restricted button</span>
                  </b-button>
                </div>
              -->

          <hr>
          <b-row>
            <b-col
              cols="12"
              md="4"
            />
            <b-col
              cols="12"
              md="4"
              class="text-center mb-1"
            >
              <b-img
                :src="signature"
                text=""
                variant="light-success"
                height="80px"
                class="border"
              />
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="text-center mb-1"
            >
              <div class="d-inline p-1">
                <b-button
                  variant="primary"
                  @click="$refs.refInputSignature.click()"
                >
                  <input
                    ref="refInputSignature"
                    type="file"
                    class="d-none"
                    @change="handleSignatureImage()"
                  >
                  <span class="d-none d-sm-inline">{{ $t('user.updateSignature') }}</span>
                  <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
              <div class="d-inline p-1">
                <b-button
                  variant="danger"
                  @click="removeSignatureImage()"
                >
                  <span class="d-none d-sm-inline">{{ $t('user.removeSignature') }}</span>
                  <feather-icon
                    icon="TrashIcon"
                    class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- Action Buttons -->
          <hr>
          <b-row>
            <b-col>
              <router-link :to="{name: 'apps-users-list'}">
                <b-button
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                >
                  {{ $t('actions.back') }}
                </b-button>
              </router-link>
              <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="invalid"
              >
                {{ $t('actions.save') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle,
  BFormCheckbox, BInputGroup, BInputGroupAppend, BBadge, BCollapse, BImg,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { avatarText } from '@core/utils/filter'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { hasRole } from '@/auth/utils'
import UserEditPassword from './EditPassword.vue'
import DualSelect from './DualSelect.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    BCollapse,
    BImg,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    UserEditPassword,
    DualSelect,
  },
  mixins: [togglePasswordVisibility],
  props: {
    userData: {
      type: Object,
      required: true,
    },
    newUser: {
      type: Boolean,
      required: true,
    },
    jobList: {
      type: Array,
      required: true,
    },
    idTypeList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    municipalityList: {
      type: Array,
      required: true,
    },
    userAvatar: {
      type: String,
      required: true,
    },
    userSignature: {
      type: String,
      required: true,
    },
    roleList: {
      type: Array,
      required: true,
    },
    arlList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // validation
      required,
      email,
      passwordFieldType: 'password',
      avatarUpdated: false,
      signatureUpdated: false,
      passwordConfirmation: '',
      medicalRecord: false,
      medicalRecordRules: null,
      occupationalMedicalLicenseRules: null,
      arlRules: null,
      arlRequired: false,
      avatar: null,
      signature: null,
      rightUsers: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    userData(newValue, oldValue) {
      this.checkJobTitle()
    },
    userAvatar(newValue, oldValue) {
      this.avatar = newValue
    },
    userSignature(newValue, oldValue) {
      this.signature = newValue
    },
  },
  methods: {
    hasRole(roleKey) {
      return hasRole(roleKey)
    },
    onRoleChange() {
      if (this.userData && this.userData.roles) {
        if (this.userData.roles.filter(e => e.key === 'ARL_USER').length > 0) {
          this.arlRules = { required: true }
          this.arlRequired = true
          return
        }
      }
      this.arlRules = null
      this.arlRequired = false
    },
    getEmptyError(errors) {
      if (errors && errors.length > 0) {
        return this.$t('user.roles.required')
      }
      return ''
    },
    resolveUserStatusVariant(status) {
      if (status == 0) return 'warning'
      return 'success'
    },
    resolveUserStatusMessage(status) {
      if (status == 0) return this.$t('user.statusValue.inactive')
      return this.$t('user.statusValue.active')
    },
    onJobTitleChange() {
      this.checkJobTitle()
    },
    checkJobTitle() {
      this.medicalRecord = false
      this.medicalRecordRules = null
      for (let i = 0; i < this.jobList.length; i++) {
        if (this.jobList[i].id == this.userData.job_title_id) {
          if (this.jobList[i].key == 'MED') {
            this.medicalRecord = true
            this.medicalRecordRules = { required: true }
            this.occupationalMedicalLicenseRules = { required: true }
          }
        }
      }
    },
    onDepartmentChange() {
      this.userData.municipality_id = null
      this.$parent.fetchCities(this.userData.department_id)
    },
    handleAvatarImage() {
      this.createAvatarBase64mage(this.$refs.refInputAvatar.files[0])
      this.avatarUpdated = true
    },
    handleSignatureImage() {
      this.createSignatureBase64mage(this.$refs.refInputSignature.files[0])
      this.signatureUpdated = true
    },
    createAvatarBase64mage(avatar) {
      const reader = new FileReader()
      reader.onload = e => {
        this.avatar = e.target.result
      }
      reader.readAsDataURL(avatar)
    },
    createSignatureBase64mage(avatar) {
      const reader = new FileReader()
      reader.onload = e => {
        this.signature = e.target.result
      }
      reader.readAsDataURL(avatar)
    },
    removeAvatarImage() {
      this.avatarUpdated = true
      this.avatar = ''
      this.userData.avatar_id = null
      this.$refs.refInputAvatar.value = ''
    },
    removeSignatureImage() {
      this.signatureUpdated = true
      this.signature = ''
      this.userData.signature_id = null
      this.$refs.refInputSignature.value = ''
    },
    getJobTitle(user) {
      return user.job_title ? user.job_title.name : ''
    },
    avatarText,
    save() {
      this.$refs.userForm.validate().then(success => {
        if (success) {
          if ('arl' in this.userData) {
            delete this.userData.arl
            if (!this.arlRequired) {
              this.userData.arl_id = null
            }
          }
          if ('document_type' in this.userData) {
            delete this.userData.document_type
          }
          if ('job_title' in this.userData) {
            delete this.userData.job_title
          }
          if ('department' in this.userData) {
            delete this.userData.department
          }
          if ('municipality' in this.userData) {
            delete this.userData.municipality
          }
          if (!('status' in this.userData)) {
            this.userData.status = 0
          }
          if (this.avatar && this.avatarUpdated) {
            this.userData.avatar = this.avatar
          }
          if (this.signature && this.signatureUpdated) {
            this.userData.signature = this.signature
          }
          this.$parent.save(this.userData)
        }
      })
    },
  },
}
</script>

<style>

</style>
