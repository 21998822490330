<template>
  <div>
    <b-collapse
      id="password-collapse"
      v-model="passwordCollapsed"
    >
      <!-- User Info: Input Fields -->
      <validation-observer
        ref="userPasswordForm"
        #default="{ invalid }"
      >
        <b-form
          @submit.prevent="updatePassword"
        >
          <b-form-group
            :label="$t('user.password')"
            label-for="password"
          >
            <template v-slot:label>
              {{ $t('user.password') }} <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              :name="$t('user.password')"
              :rules="passwordRules"
              vid="password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
                autocomplete="off"
              >
                <b-form-input
                  id="password"
                  v-model="password"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('user.passwordConfirmation')"
            label-for="password"
          >
            <template v-slot:label>
              {{ $t('user.passwordConfirmation') }} <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              :name="$t('user.passwordConfirmation')"
              :rules="passwordConfirmationRules"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
                autocomplete="off"
              >
                <b-form-input
                  id="password_confirmation"
                  v-model="passwordConfirmation"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            variant="primary"
            type="submit"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :disabled="invalid"
          >
            {{ $t('user.savePassword') }}
          </b-button>
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="cancel"
          >
            {{ $t('user.cancelSavePassword') }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-collapse>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle,
  BFormCheckbox, BInputGroup, BInputGroupAppend, BFormSelect, BBadge, BCollapse,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BBadge,
    BCollapse,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  props: {
    userData: {
      type: Object,
      required: true,
    },
    newUser: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // validation
      required,
      passwordFieldType: 'password',
      password: '',
      passwordConfirmation: '',
      passwordCollapsed: false,
      passwordRules: null,
      passwordConfirmationRules: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    passwordCollapsed(newValue, oldValue) {
      if (newValue) {
        this.passwordRules = { required: true, confirmed: false }
        this.passwordConfirmationRules = { required: true, confirmed: 'password' }
      } else {
        this.passwordRules = null
        this.passwordConfirmationRules = null
        // this.$refs.userPasswordForm.reset();
      }
    },
  },
  methods: {
    cancel() {
      this.passwordCollapsed = false
    },
    updatePassword() {
      const self = this
      this.$refs.userPasswordForm.validate().then(success => {
        if (success) {
          self.$parent.$parent.$parent.updatePassword({ password: self.password })
          self.password = ''
          self.passwordConfirmation = ''
          self.passwordCollapsed = false
          self.$refs.userPasswordForm.reset()
        }
      })
    },
  },
}
</script>

<style>

</style>
