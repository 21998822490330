<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
    </b-alert>

    <div class="text-primary h2">
      <feather-icon
        icon="UserIcon"
        size="25"
        class="mr-0 mr-sm-50"
        variant="primary"
      />
      <span
        v-if="newUser"
        class="d-none d-sm-inline"
      >Crear Usuario</span>
      <span
        v-else
        class="d-none d-sm-inline"
      >Editar Usuario</span>
    </div>
    <user-edit-account
      :user-data="userData"
      :new-user="newUser"
      :job-list="jobList"
      :id-type-list="idTypeList"
      :department-list="departmentList"
      :municipality-list="municipalityList"
      :user-avatar="avatar"
      :user-signature="signature"
      :role-list="roleList"
      :arl-list="arlList"
      class="mt-2 pt-75"
    />
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserStoreModule from '../storeModule'
import UserEditAccount from './EditAccount.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    UserEditAccount,
  },
  data() {
    return {
      newUser: true,
      userData: {
        roles: [],
      },
      arlList: [],
      jobList: [],
      idTypeList: [],
      departmentList: [],
      municipalityList: [],
      roleList: [],
      avatar: '',
      signature: '',
      USER_APP_STORE_MODULE_NAME: 'app-user',
      errorMessages: {
        unexpectedError: this.$t('user.error.unexpectedError'),
        usernameExist: this.$t('user.error.usernameExist'),
        userEmailExist: this.$t('user.error.userEmailExist'),

      },
    }
  },
  async mounted() {
    // Register module
    if (!store.hasModule(this.USER_APP_STORE_MODULE_NAME)) store.registerModule(this.USER_APP_STORE_MODULE_NAME, UserStoreModule)

    this.fetchJobs()
    this.fetchIdTypes()
    this.fetchRoles()
    this.fetchArls()
    await this.fetchDepartments()
    if (router.currentRoute.params.id !== undefined) {
      this.newUser = false
      await this.fetchUser()
      this.fetchCities(this.userData.department_id)
    } else {
      this.userData = {
        status: 0, medical_record: null, occupational_medical_license: null, status: 1, roles: [],
      }
    }
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.USER_APP_STORE_MODULE_NAME)) store.unregisterModule(this.USER_APP_STORE_MODULE_NAME)
  },
  methods: {
    fetchArls() {
      store.dispatch('app-user/fetchArls', { })
        .then(response => {
          this.arlList = response.data.items.filter(item => item.status === 1)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.idTypeList = undefined
          }
        })
    },
    fetchRoles() {
      store.dispatch('app-user/fetchRoles')
        .then(response => {
          this.roleList = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.idTypeList = undefined
          }
        })
    },
    fetchDepartments() {
      store.dispatch('app-user/fetchDepartments')
        .then(response => {
          this.departmentList = response.data.items
          this.departmentList.unshift({ id: null, value: this.$t('list.defaultOption') })
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.idTypeList = undefined
          }
        })
    },
    fetchCities(department_id) {
      store.dispatch('app-user/fetchCities', { parent: department_id })
        .then(response => {
          this.municipalityList = response.data.items
          this.municipalityList.unshift({ id: null, value: this.$t('list.defaultOption') })
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.idTypeList = undefined
          }
        })
    },
    fetchJobs() {
      store.dispatch('app-user/fetchJobs')
        .then(response => { this.jobList = response.data.items })
        .catch(error => {
          if (error.response.status === 404) {
            this.jobList = undefined
          }
        })
    },
    fetchIdTypes() {
      store.dispatch('app-user/fetchIdTypes')
        .then(response => { this.idTypeList = response.data.items })
        .catch(error => {
          if (error.response.status === 404) {
            this.idTypeList = undefined
          }
        })
    },
    fetchUser() {
      store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
        .then(response => {
          this.userData = response.data

          if (this.userData.avatar_id) {
            this.fetchAvatar(this.userData.avatar_id)
          }
          if (this.userData.signature_id) {
            this.fetchSignature(this.userData.signature_id)
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.userData)
            userData = undefined
          }
        })
    },
    fetchAvatar(avatar_id) {
      store.dispatch('app-user/fetchImage', { id: avatar_id })
        .then(response => {
          this.avatar = response.data.img
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.userData)
            userData = undefined
          }
        })
    },
    fetchSignature(signature_id) {
      store.dispatch('app-user/fetchImage', { id: signature_id })
        .then(response => {
          this.signature = response.data.img
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.userData)
            userData = undefined
          }
        })
    },
    save(userData) {
      self = this
      if (this.newUser) {
        store.dispatch('app-user/addUser', userData)
          .then(response => {
            this.userData = response.data
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Usuario creado',
                icon: 'SaveIcon',
                variant: 'success',
                text: `El usuario ${this.userData.username}. Fue creado correctamente`,
              },
            })
            this.$router.replace({ name: 'apps-users-list' })
          })
          .catch(error => {
            self.errorMesage = this.$t('user.error.unexpectedError')

            if (error.response.data) {
              const key = error.response.data.message
              if (key in self.errorMessages) {
                self.errorMesage = self.errorMessages[key]
              }
            }
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('user.error.title'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: self.errorMesage,
              },
            })
          })
      } else {
        store.dispatch('app-user/editUser', { id: router.currentRoute.params.id, userData })
          .then(response => {
            this.userData = response.data
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Usuario actualizado',
                icon: 'SaveIcon',
                variant: 'success',
                text: `El usuario ${this.userData.username}. Fue actualizado correctamente`,
              },
            })
            this.$router.replace({ name: 'apps-users-list' })
          })
          .catch(error => {
            userData.value = undefined
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error al actualizar el usuario',
                icon: 'SaveIcon',
                variant: 'danger',
                text: `Se presentó un error al intentar actualizar el usuario ${this.userData.username}.`,
              },
            })
          })
      }
    },
    updatePassword(userPassword) {
      self = this
      store.dispatch('app-user/updatePassword', { id: router.currentRoute.params.id, userPassword })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Contraseña actualizada',
              icon: 'SaveIcon',
              variant: 'success',
              text: `La contraseña del usuario ${self.userData.username}. Fue actualizada correctamente`,
            },
          })
          // TODO close modal
        })
        .catch(error => {
          if (error.response.status === 404) {

          }
        })
    },
  },
}
</script>

<style>

</style>
